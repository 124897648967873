//Controller
import BaseController from "@/controllers/base-controller";

//Standard Components
import GlobalServices from "@/services/global";
import ApplicationService from "@/services/application";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import APP from "@/constants/application";
import UtilityString from "@/utilities/strings";

import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";
import {IController} from "@/interfaces/IController";
import cacheService from "@/services/cache";
import SearchService from "@/services/search";

//Interfaces
import {IUXSidePanel, ISidePanelHeader, IActionPrint, ILabelTitle, IButtonAction, ISidePanelInfoSection, IJupiterIFrame, ISidePanelTabs} from "@/interfaces/UXSidePanel";

import {IUXList, IListData, ITableHeader, ITable, ITableHoverMenu, ITableAction, IEditAction, IRoute} from "@/interfaces/IUXList";
import {IInfo} from "@/interfaces/IUXInfo";

//Services
import {IFilterClient, IFilterItem} from "@/interfaces/IUXFilters";
import store from "@/store";

class PaymentController implements IController {
  private config = new API_CONFIG();
  private auth = AuthService.getInstance();
  private utility = new UtilityString();
  private app = new ApplicationService();
  private global = new GlobalServices();

  private search = new SearchService();

  _actionId = MODULE.PAYMENT.ActionId;
  _moduleId = MODULE.PAYMENT.ModuleId;

  //Standard application event types, to standardize event logs and modal events
  EVENT_ACTION = EVENTS;

  //Stores the full data object state of the side panel to be returned.
  _sidepanel: IUXSidePanel = new Object() as IUXSidePanel;

  //Stores the default action, if the side panel action button is clicked without choosing an option from the list
  _defaultAction: IButtonAction = new Object() as IButtonAction;

  //The data object being passed in, eg.. Project, Client , Quote Data etc..
  _data = null;

  //Standard functionality such as print, email, close are stored in the base controller
  _base = new BaseController();

  //Reference to project earth for backward compability
  earth = new EarthApplicationService();

  //Constructor Takes in a Module Data Object (Project, Client, Invoice)
  constructor() {}

  //Page Header / Title
  getTitle(): String {
    let balance = "";

    if (this._data.approvedTotal) balance = "Balance: " + this._data.approvedTotal;
    else balance = "Paid";

    let title = this._base.getTitle("Invoice", this._data.referenceNumber, balance);

    return title;
  }

  getSubTitle() {
    let subtitle = this._base.getSubTitle(this._data.name, this._data.location);

    return subtitle;
  }

  //Generate Drop Down Menu for Action Items
  getDropDownMenu(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Purchase Order
    let purchaseOrderUrl = this.earth.getNewPurchaseOrderNavigationUrl(this._data.intProjectID);
    actions.push({
      id: 1,
      eventType: this.EVENT_ACTION.PURCHASEORDER.New,
      menuTitle: "Create a Purchase Order",
      modalTitle: "Create New Purchase Order",
      saveButtonTitle: "Create Purchase Order",
      data: this._data,
      componentName: "",
      url: purchaseOrderUrl,
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Invoice
    let invoiceAction = {
      id: 2,
      eventType: this.EVENT_ACTION.INVOICE.New,
      menuTitle: "Create an Invoice",
      modalTitle: "Create New Invoice",
      componentName: "NewInvoice",
      saveButtonTitle: "Create Invoice",
      data: this._data,
      UI_TYPE: APP.UI_TYPE.Modal,
    };

    //Set Default Action
    this._defaultAction = invoiceAction;

    actions.push(invoiceAction);

    //Quote
    let quoteUrl = this.earth.getNewQuoteNavigationUrl(this._data.intProjectID);
    actions.push({
      id: 3,
      eventType: this.EVENT_ACTION.INVOICE.New,
      menuTitle: "Create a Quote",
      modalTitle: "Create New Quote",
      saveButtonTitle: "Create Quote",
      data: this._data,
      componentName: "",
      url: quoteUrl,
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Old Work Order Screen
    let pageTitle = this.getTitle();
    let earthUrl = this.earth.getWorkOrderDetailsUrl(this._data.intProjectID, pageTitle);

    actions.push({
      id: 4,
      eventType: this.EVENT_ACTION.PROJECTS.ViewOld,
      menuTitle: "View Old Work Order",
      modalTitle: "Old Work Order Page",
      saveButtonTitle: "Old Work Order",
      data: this._data,
      componentName: "",
      url: earthUrl,
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    return actions;
  }

  //SECTION - Header
  getHeader(): ISidePanelHeader {
    let headerDataObj: ISidePanelHeader = new Object() as ISidePanelHeader;

    //Hide/Show Print Preview Button
    headerDataObj.isPreviewEnabled = true;

    //Hide/Show Email Button
    headerDataObj.isEmailEnabled = true;

    //Hide/Show Action Button
    headerDataObj.isActionButtonEnabled = false;

    headerDataObj.titleSection = new Object() as ILabelTitle;

    //Title Section (Left)
    headerDataObj.titleSection.headTitle = this.getTitle();

    //Sub Title
    headerDataObj.titleSection.subTitle = this.getSubTitle();

    //Action Button
    headerDataObj.actions = this.getDropDownMenu();

    headerDataObj.defaultAction = this._defaultAction;

    //Action Button
    if (headerDataObj.isActionButtonEnabled) {
      headerDataObj.actions = this.getDropDownMenu();
      headerDataObj.defaultAction = this._defaultAction;
    }

    //Print Button
    if (headerDataObj.isPreviewEnabled) {
      headerDataObj.print = this._base.getPrintAction(this._data.id, this._moduleId);
    }

    //Email Button
    if (headerDataObj.isEmailEnabled) {
      headerDataObj.email = this._base.getEmailAction(this._data, this._moduleId, this._actionId);
    }

    return headerDataObj;
  }

  //SECTION - Action Bar
  getActionBar(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Edit Client
    let clientUrl = this.earth.getViewClientNavigationUrl(this._data.intClientID, this._data.businessName);

    actions.push({
      id: 1,
      eventType: this.EVENT_ACTION.CLIENTS.View,
      menuTitle: "Edit Client",
      modalTitle: "Editing Client",
      saveButtonTitle: "Save Changes",
      data: this._data,
      componentName: "",
      url: clientUrl,
      icon: "fa fa-pencil",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Map
    let mapUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(this._data.location);

    actions.push({
      id: 2,
      eventType: this.EVENT_ACTION.CLIENTS.ExternalView,
      menuTitle: "Map",
      modalTitle: "",
      saveButtonTitle: "",
      data: this._data,
      componentName: "",
      url: mapUrl,
      icon: "fa fa-map-marker",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Dispatch
    actions.push({
      id: 3,
      eventType: this.EVENT_ACTION.DISPATCH.New,
      menuTitle: "Dispatch",
      modalTitle: "Dispatch to Technician",
      saveButtonTitle: "Send Message",
      data: this._data,
      componentName: "NewDispatch",
      url: "",
      icon: "fa fa-truck",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    //Sales Quote Navigation
    //If has sales quotes
    if (this._data.intSalesQuotationID > 0) {
      let quoteUrl = this.earth.getViewQuoteDetailsUrl(this._data.intSalesQuotationID);

      actions.push({
        id: 4,
        eventType: this.EVENT_ACTION.CLIENTS.View,
        menuTitle: "View Quote",
        modalTitle: "Editing Quote",
        saveButtonTitle: "Save Changes",
        data: this._data,
        componentName: "",
        url: quoteUrl,
        icon: "fa fa-file-text-o",
        UI_TYPE: APP.UI_TYPE.NewTab,
      });
    }

    return actions;
  }

  //SECTION - Info Summary - Read only
  getInfoSectionDetails(): ISidePanelInfoSection {
    let infosection: ISidePanelInfoSection = {
      //Left Side of info section
      leftSection: {
        title: "Due Date:" + " " + this._data.displayDueDate, //Red title H3 font size
        labels: [
          {
            id: 0,
            title: "Start Date: ",
            labelValue: this._data.displayStartDate,
          },
          {
            id: 1,
            title: "Est. Completion Date: ",
            labelValue: this._data.displayEndDate,
          },
        ],
      },

      //Right Side of info section
      rightSection: {
        title: "OVERDUE BY 1 DAY", //Red title H3 font size
        labels: [
          //{ id: 0, title: "Total: ", labelValue: this.$options.filters.currency(project.totalAmonunt) },
          {
            id: 1,
            title: "Created: ",
            labelValue: this._data.displayCreatedDate,
          },
          {id: 2, title: "Created By: ", labelValue: this._data.createdBy},
          // { id: 3, title: "Outstanding Balance: ", labelValue: Vue.$options.filters.currency(project.totalAmonunt) },
        ],
      },
    };

    return infosection;
  }

  //SECTION - Tabs
  getTabSection(): ISidePanelTabs[] {
    let tabs: ISidePanelTabs[] = [];

    //TAB - Location
    let detailsTab = {
      id: 1,
      title: "Location", //Name of the title
      actionButton: null,
      componentName: "ProjectDetails",
      componentPath: "@/components/projects/details",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(detailsTab);

    //TAB - Contacts
    let notesTab = {
      id: 2,
      title: "Contacts", //Name of the tab
      actionButton: null,
      componentName: "Notes",
      componentPath: "@/components/_universal/notes",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(notesTab);

    //TAB - Documents/Photos
    let customTab = {
      id: 3,
      title: "Documents/Photos", //Name of the tab
      actionButton: null,
      editButton: this.getActionButton(MODULE.DOCUMENTS, "edit", APP.UI_TYPE.ModalIFrame),
      componentName: "TabIframeComponent",
      componentPath: "@/components/projects/custom",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      url: this._sidepanel.iFrame.customUrl,
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(customTab);

    //TAB - Billing Rates
    let permitsTab = {
      id: 4,
      title: "Billing Rates", //Name of the Tab
      actionButton: null,
      componentName: "permits",
      componentPath: "@/components/projects/project-tab-permits",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(permitsTab);

    //TAB - Accounts Receivable
    let purchaseOrderTab = {
      id: 5,
      title: "Accounts Receivable", //Name of the Tab
      actionButton: null,
      componentName: "PurchaseOrder",
      componentPath: "@/components/projects/project-tab-purchase-order",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(purchaseOrderTab);

    //TAB - Notes
    let invoiceTab = {
      id: 6,
      title: "Invoices", //Name of the Tab
      actionButton: null,
      componentName: "Invoice",
      componentPath: "@/components/projects/project-tab-invoices",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(invoiceTab);

    //TAB - Equipment
    let equipmentTab = {
      id: 7,
      title: "Equipments", //Name of the Tab
      actionButton: {
        id: 1,
        eventType: this.EVENT_ACTION.EQUIPMENT.New,
        menuTitle: "Add Equipment",
        modalTitle: "Add Equipment",
        saveButtonTitle: "Save",
        componentName: this.EVENT_ACTION.IFRAME.AddEquipment,
        data: this._data,
        icon: "fa fa-pencil",
        UI_TYPE: APP.UI_TYPE.ModalIFrame,
      },
      componentName: "TabIframeComponent",
      componentPath: "@/components/projects/project-tab-equipment",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      url: this._sidepanel.iFrame.equipmentUrl,
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(equipmentTab);

    //TAB - History
    let historyTab = {
      id: 12,
      title: "History", //Name of the Tab
      actionButton: null,
      componentName: "Activity",
      componentPath: "@/components/activity/activity-history",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(historyTab);

    return tabs;
  }

  //Dynamically Create Action Button Properties
  getActionButton(module, actionType, UI_MODAL_TYPE) {
    let title = "";
    let componentName = "";
    let eventType = this.EVENT_ACTION.DOCUMENTS;

    //Set Billing Rates Tab Details
    if (module === MODULE.DOCUMENTS) {
      eventType = this.EVENT_ACTION.DOCUMENTS;

      if (actionType === "new") {
        title = "Add Documents/Photos";
        componentName = this.EVENT_ACTION.IFRAME.AddClientDocument;
      } else if (actionType === "edit") {
        title = "Preview Document/Photo";
        componentName = this.EVENT_ACTION.IFRAME.PreviewImage;
      }
    }

    if (actionType === "new") {
      return {
        id: 1,
        actionId: module.ActionId,
        eventType: eventType.New,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: false,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
      };
    } else if (actionType === "edit") {
      return {
        id: 2,
        actionId: module.ActionId,
        eventType: eventType.View,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: true,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
      };
    }
  }

  getListHeaders(): ITableHeader[] {
    let header: ITableHeader[] = [];

    header.push({
      id: 0,
      title: "Invoice #",
      columnName: "referenceNumber",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 1,
      title: "Work Order #",
      columnName: "workOrder",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 2,
      title: "Client",
      columnName: "name",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 3,
      title: "Payment Amount",
      columnName: "total",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    // header.push({
    //   id: 4,
    //   title: "Total",
    //   columnName: "total",
    //   isSortable: false,
    //   isVisible: true,
    //   rightAlign: true,
    // });

    header.push({
      id: 6,
      title: "Balance",
      columnName: "balance",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    header.push({
      id: 7,
      title: "Notes",
      columnName: "notes",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 4,
      title: "Type",
      columnName: "type",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 8,
      title: "Payment Date",
      columnName: "createdDate",
      isSortable: false,
      isVisible: true,
    });

    return header;
  }

  getHoverMenu(): ITableHoverMenu {
    let hover = new Object() as ITableHoverMenu;

    //Not implemented for now
    hover.isEnabled = false;

    return hover;
  }

  getActions(): ITableAction {
    let actions = new Object() as ITableAction;

    //Not implemented for now
    actions.isEnabled = false;

    return actions;
  }

  //Info stats returned at the top of every list page.
  async getInfoList(): Promise<IInfo> {
    let info = new Object() as IInfo;

    //Not implemented for now
    info.isEnabled = false;

    let filter = {
      actionId: this._actionId,
    };

    let results = await this.search.getResults("global/list/info", filter);

    info.data = results.resources;

    return info;
  }

  //Dynamically determin the filters for each list page...
  async getFilters(): Promise<IFilterClient> {
    let filter = new Object() as IFilterClient;
    let filters: IFilterItem[] = [];

    //If the advance filter button options should show
    filter.hasAdvancedFilter = false;

    //Not implemented for now
    filter.isEnabled = false;

    //Filter by Invoice / Job #
    filters.push({
      id: 0,
      title: "Invoice/Job #",
      name: "invoiceno",
      uxtype: "textbox",
      data: null,
      selectedValue: "",
      defaultValue: "",
      query: "i.invoiceno",
      isfilterByDisplayName: true,
    });

    filters.push({
      id: 1,
      title: "Client",
      name: "client",
      uxtype: "dropdown",
      data: await this.global.getUXDropDown(MODULE.CLIENTS.ActionId),
      selectedValue: "",
      defaultValue: "",
      query: "IntClientID",
      isfilterByDisplayName: false,
    });

    //Filter by Status
    filters.push({
      id: 2,
      title: "Payment Type",
      name: "paymentType",
      uxtype: "dropdown",
      data: await this.global.getUXDropDown(MODULE.PAYMENT.UX.PaymentType),
      selectedValue: "",
      defaultValue: "",
      query: "IntPaymentTypeID",
      isfilterByDisplayName: false,
    });

    filter.filters = filters;

    return filter;
  }

  async getListResults(filter) {
    filter.actionId = this._actionId;

    return await this.search.getResults("global/list", filter);
  }

  //LIST - Entry for list page
  async list(): Promise<IUXList> {
    let list: IUXList = new Object() as IUXList;

    list.actionId = this._actionId;

    //settings
    list.settings = {
      isHoverOverRowEnabled: false,
    };

    //Table Header Properties
    list.table = new Object() as ITable;
    list.table.header = this.getListHeaders();

    //Data
    list.table.data = await this.getListResults({});

    //List Table
    list.info = await this.getInfoList();

    //True - if we should load old ProjectEarth implementation via Iframe
    list.isSidePanelFrame = true;

    //Filters
    list.filter = await this.getFilters();

    //Hover Menu
    list.table.hover = new Object() as ITableHoverMenu;
    list.table.hover = this.getHoverMenu();

    //More Options - Action Menu
    list.table.actions = new Object() as ITableAction;
    list.table.actions = this.getActions();

    //Edit Action - i.e. when user clicks Edit, what should happen.
    list.editAction = new Object() as IEditAction;
    list.editAction.route = new Object() as IRoute;
    list.editAction.route = {
      name: "payment-details",
      path: "/payments/details",
      param: {
        id: 0, //Id will be set once the record has been clicked
      },
    };

    return list;
  }

  //MAIN - Entry for Details page
  async main(data): Promise<IUXSidePanel> {
    this._data = data;

    this._sidepanel = store.getters.getSidePanel;
    this._sidepanel.actionId = this._actionId;

    //Specify the name of the page being loaded
    this._sidepanel.pageName = APP.PAGE.Payment.Details;

    //Header
    this._sidepanel.headerDataObj = this.getHeader();

    //Decide if Side panel should show Iframe or load component view
    if (this._sidepanel.isSidePanelFrame) {
      //NOT LEGACY PLATFORM EXIST FOR PAYMENTS
    } //IF not an IFrame then load standard data component
    else {
      //Load details data
      this._sidepanel.details = await this.global.getEditDetails(this._actionId, data.id);

      //Action Bar
      this._sidepanel.actionBarDataObj = this.getActionBar();

      //Info Section
      this._sidepanel.infoSectionDataObj = this.getInfoSectionDetails();

      //External Links
      this._sidepanel.iFrame = this._base.getIFrameDetails(this._data, this._data.id);

      this._sidepanel.moduleId = this._moduleId;

      //Tabs
      this._sidepanel.tabSectionDataObj = this.getTabSection();
    }

    return this._sidepanel;
  }
}

export default PaymentController;
